// frontend/src/i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false, // Disable debug mode to reduce logging
    ns: [
      'footer',
      'header',
      'preferences',
      'privacy',
      'saved_attractions',
      'search_form',
      'search_results',
    ], // Add all your namespaces here
    defaultNS: 'translation',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // Ensure this matches your folder structure
    },
    detection: {
      order: ['navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    react: {
      useSuspense: false,
    },
  }, (err, t) => {
    if (err) console.error('i18n init error:', err);
    else console.log('i18n initialized');
  });

i18n.on('loaded', (loaded) => {
  // console.log('Namespaces loaded:', loaded);
});

export default i18n;
