import React, { useEffect, useState, useRef } from 'react';
import './LoadingMessage.less';

const getPreviousTopics = () => {
  const topics = localStorage.getItem('previous_topics');
  return topics ? JSON.parse(topics) : [];
};

const saveTopic = (topic) => {
  const topics = getPreviousTopics();
  const currentTime = new Date().getTime();

  // Remove topics older than 2 days
  const filteredTopics = topics.filter(t => currentTime - t.timestamp < 2 * 24 * 60 * 60 * 1000);

  // Add new topic
  filteredTopics.push({ topic, timestamp: currentTime });

  // Limit to the latest 100 topics
  if (filteredTopics.length > 100) {
    filteredTopics.shift();
  }

  localStorage.setItem('previous_topics', JSON.stringify(filteredTopics));
};

const fetchLoadingMessage = async (type, identifier, language, storedPreferences, setLoadingMessage, fetchRef, from, to) => {
  if (fetchRef.current) {
    return;
  }
  fetchRef.current = true;

  // Get topics from local storage and filter out null values
  const previousTopics = getPreviousTopics().map((obj) => obj.topic).filter((topic) => typeof topic === 'string');

  try {
    let url = '';
    let body = {};

    if (type === 'location') {
      url = '/api/loading-message-location';
      body = { location: identifier, previous_topics: previousTopics, search_preferences: storedPreferences, language };
    } else if (type === 'description') {
      url = '/api/loading-message-description';
      body = { description: identifier, previous_topics: previousTopics, search_preferences: storedPreferences, language };
    } else if (type === 'tour') {
      url = '/api/loading-message-tour';
      body = { from, to, previous_topics: previousTopics, search_preferences: storedPreferences, language };
    } else {
      console.error('Invalid loading message type:', type);
      return;
    }

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) throw new Error('Network response was not ok.');

    const data = await response.json();
    const { message, topic } = data;

    setLoadingMessage(message);
    saveTopic(topic);
  } catch (error) {
    console.error('Failed to fetch loading message:', error);
  } finally {
    fetchRef.current = false;
  }
};

const LoadingMessage = ({ type, identifier, language, storedPreferences, from, to }) => {
  const [loadingMessage, setLoadingMessage] = useState('');
  const fetchRef = useRef(false);

  useEffect(() => {
    if (type && (identifier || (from && to)) && language && storedPreferences) {
      fetchLoadingMessage(type, identifier, language, storedPreferences, setLoadingMessage, fetchRef, from, to);
    }
  }, [type, identifier, language, storedPreferences, from, to]);

  return (
    <div className={`loading-message-container animate__animated ${loadingMessage ? 'animate__zoomInUp' : ''}`}>
      <p>{loadingMessage}</p>
    </div>
  );
};

export default LoadingMessage;
