// frontend/src/components/PrivacyPolicyPage/PrivacyPolicyPage.js

import React from 'react';
import { useTranslation } from 'react-i18next';
import './PrivacyPolicyPage.less';
import BaseLayout from '../Common/BaseLayout';

const PrivacyPolicyPage = () => {
  const { t } = useTranslation('privacy');

  return (
    <BaseLayout title={t('privacy_policy')} subtitle={t('privacy_policy_subtitle')}>
      <div className="privacy-policy-container">
        <h1>{t('privacy_policy_heading')}</h1>
        <p>{t('privacy_policy_intro')}</p>
        
        <h2>{t('information_we_collect_heading')}</h2>
        <p>{t('information_we_collect_text')}</p>
        
        <h2>{t('how_we_use_information_heading')}</h2>
        <p>{t('how_we_use_information_text')}</p>
        
        <h2>{t('data_protection_heading')}</h2>
        <p>{t('data_protection_text')}</p>
        
        <h2>{t('your_rights_heading')}</h2>
        <p>{t('your_rights_text')}</p>
      </div>
    </BaseLayout>
  );
};

export default PrivacyPolicyPage;
