// src/components/Common/BaseLayout.js

import React from 'react';
import './BaseLayout.less';

const BaseLayout = ({ title, subtitle, children }) => {
  return (
    <div className="layout-container">
      <div className="layout-title">
        <h1>{title}</h1>
        <p className="layout-subtitle">{subtitle}</p>
      </div>
      {children}
    </div>
  );
};

export default BaseLayout;
