// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-message-container {
  text-align: center;
  color: #000000;
  margin: 20px 0;
  font-size: 24px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/LoadingMessage/LoadingMessage.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,cAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;AACJ","sourcesContent":[".loading-message-container {\n    text-align: center;\n    color: #000000; // Black text color for the loading message\n    margin: 20px 0; // Space around the loading message\n    font-size: 24px; // Adjust the font size if needed\n    font-weight: bold;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
