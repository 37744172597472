// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: black;
  color: grey;
  padding: 20px 0;
  display: flex;
  justify-content: center;
}
.footer-container {
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  text-align: center;
}
.disclaimer {
  font-size: 1.1em;
  margin: 0;
  font-weight: bold;
}
.disclaimer a {
  color: #4CAF50;
}
@media (max-width: 768px) {
  .disclaimer {
    font-size: 0.7em;
    margin: 0;
    font-weight: bold;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.less"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,WAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;AACJ;AAEA;EACI,WAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;AAAJ;AAGA;EACI,gBAAA;EACA,SAAA;EACA,iBAAA;AADJ;AAFA;EAMQ,cAAA;AADR;AAKA;EACI;IACI,gBAAA;IACA,SAAA;IACA,iBAAA;EAHN;AACF","sourcesContent":[".footer {\n    background-color: black;\n    color: grey;\n    padding: 20px 0;\n    display: flex;\n    justify-content: center;\n}\n\n.footer-container {\n    width: 100%;\n    max-width: 1200px; // Adjust the max-width as needed\n    padding: 0 20px;\n    text-align: center;\n}\n\n.disclaimer {\n    font-size: 1.1em;\n    margin: 0;\n    font-weight: bold;\n\n    a {\n        color: #4CAF50;\n    }\n}\n\n@media (max-width: 768px) {\n    .disclaimer {\n        font-size: .7em;\n        margin: 0;\n        font-weight: bold;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
