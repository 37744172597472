// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location-message-container {
  text-align: center;
  color: #000000;
  margin: 20px 0;
  font-size: 1.2em;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/AttractionsByLocationPage/AttractionsByLocationPage.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,cAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;AACJ","sourcesContent":[".location-message-container {\n    text-align: center;\n    color: #000000;\n    margin: 20px 0;\n    font-size: 1.2em;\n    font-weight: bold;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
