import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import './AttractionsByDescriptionPage.less';
import '../Common/SearchForm.less';
import PreferencesPage from '../PreferencesPage/PreferencesPage';
import SearchResultsPage from '../SearchResultsPage/SearchResultsPage';
import LoadingMessage from '../LoadingMessage/LoadingMessage';
import { FaCog, FaSpinner, FaLocationArrow } from 'react-icons/fa';
import BaseLayout from '../Common/BaseLayout';
import { Helmet } from 'react-helmet';

const AttractionsByDescriptionPage = () => {
  const { t, i18n } = useTranslation(['search_form']); // Load the necessary namespaces
  const location = useLocation();
  const [description, setDescription] = useState('');
  const [showPreferences, setShowPreferences] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [showLoadingMessage, setShowLoadingMessage] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showDefaultMessage, setShowDefaultMessage] = useState(true);

  const resultsPerPage = 8;

  const storedPreferences = useMemo(() => {
    return JSON.parse(localStorage.getItem('search_preferences')) || {};
  }, []);

  const fetchResults = useCallback(async (reset = false, query = description) => {
    if (reset) {
      setIsLoading(true);
    } else {
      setLoadingMore(true);
    }

    const data = {
      criteria: {
        count: resultsPerPage,
        description: query,
        language: i18n.language,
        preferences: storedPreferences,
      },
      new_search: reset
    };

    try {
      const response = await fetch('/api/attractions-by-description', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) throw new Error('Network response was not ok.');

      const newResults = await response.json();
      setIsLoading(false);
      setLoadingMore(false);

      if (reset) {
        setSearchResults(newResults);
        setShowMoreButton(newResults.length > 0);
      } else {
        setSearchResults(prevResults => [...prevResults, ...newResults]);
        setShowMoreButton(newResults.length > 0);
      }

      if (newResults.length > 0) {
        setShowLoadingMessage(false);
      }
    } catch (error) {
      console.error('Failed to fetch description results:', error);
      setIsLoading(false);
      setLoadingMore(false);
    }
  }, [description, i18n.language, resultsPerPage, storedPreferences]);

  const debouncedFetchResults = useMemo(
    () => _.debounce((reset, query) => fetchResults(reset, query), 300),
    [fetchResults]
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get('q');
    if (query) {
      setDescription(query);
      debouncedFetchResults(true, query);
    }
  }, [location.search, debouncedFetchResults]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!description.trim()) {
      setErrorMessage(t('search_form:please_enter_search_term'));
      return;
    }
    setErrorMessage('');
    setShowMoreButton(false);
    setLoadingMore(false);
    setShowLoadingMessage(true);
    setShowDefaultMessage(false);
    await fetchResults(true);
  };

  const handleLocationClick = () => {
    if (navigator.geolocation && !isLoadingLocation) {
      setIsLoadingLocation(true);
  
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
  
        try {
          const response = await fetch('/api/geolocate', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ lat: latitude, long: longitude, language: i18n.language })
          });
  
          if (!response.ok) throw new Error('Failed to fetch location data.');
  
          const data = await response.json();
          const locationText = data.location; // assuming 'location' is the formatted string of the place
  
          // Append location to the current description text
          setDescription(prevDesc => `${prevDesc} ${locationText}`);
  
        } catch (error) {
          console.error('Error fetching location data:', error);
        } finally {
          setIsLoadingLocation(false);
        }
      }, (error) => {
        console.error('Error getting location:', error);
        setIsLoadingLocation(false);
      });
    } else {
      console.error('Geolocation is not supported by this browser or is already loading.');
    }
  };

  const handleLoadMore = async () => {
    await fetchResults(false);
  };

  const clearSearchResults = () => {
    setSearchResults([]);
    setShowMoreButton(false);
    setShowDefaultMessage(true);
    setShowLoadingMessage(false);
  };

  useEffect(() => {
    clearSearchResults();
  }, [description]);


  // if (!i18n.isInitialized) {
  //   return <div>Loading...</div>; // Show a loading state while translations are loading
  // }

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": searchResults.map((result, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": `https://roadsideattracts.com/attractions${result.file_path.replace('.json', '.html')}`
    }))
  };

  return (
    <BaseLayout title={t('search_form:describe_your_attraction_title')} subtitle={t('search_form:describe_your_attraction_subtitle')}>
      <Helmet>
        <title>Attractions by Description - RoadsideAttracts</title>
        <meta name="description" content="Find attractions by description with RoadsideAttracts. Customize your search with various preferences and discover unique destinations." />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="attractions-form-container">
        <form onSubmit={handleSubmit} className="attractions-form">
          <div className="row">
            <div className="location-icon" onClick={handleLocationClick}>
              {isLoadingLocation ? (
                <FaSpinner className="loading-spinner" />
              ) : (
                <FaLocationArrow className="location-arrow-icon" />
              )}
            </div>
            <textarea
              placeholder={t('search_form:placeholder')}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                setShowDefaultMessage(true);
                setShowLoadingMessage(false);
              }}
              disabled={isLoading || loadingMore}
              style={{ flexGrow: 1, resize: 'none', minHeight: '40px' }}
            />
          </div>
          <div className="row">
            <div className="search-preferences-icon" onClick={() => setShowPreferences(!showPreferences)}>
              <FaCog className="preferences-icon animate__animated animate__heartBeat" />
            </div>
            <button type="submit" disabled={isLoading || loadingMore}>
              {isLoading || loadingMore ? t('search_form:loading') : t('search_form:search_now')}
            </button>
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </form>
        {showPreferences && <PreferencesPage onHidePreferences={() => setShowPreferences(false)} />}
      </div>
      {showDefaultMessage && !showLoadingMessage && !searchResults.length && (
        <div className="description-message-container animate__animated animate__zoomInUp">
          <p>{t('search_form:description_field_info_intro')}</p>
          <ul>
            <li>{t('search_form:description_field_info_item_1')}</li>
            <li>{t('search_form:description_field_info_item_2')}</li>
            <li>{t('search_form:description_field_info_item_3')}</li>
            <li>{t('search_form:description_field_info_item_4')}</li>
            <li>{t('search_form:description_field_info_item_5')}</li>
            <li>{t('search_form:description_field_info_item_6')}</li>
            <li>{t('search_form:description_field_info_item_7')}</li>
          </ul>
        </div>
      )}
      {isLoading && !showLoadingMessage && (
        <div className="loading-container">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      )}
      {showLoadingMessage && searchResults.length === 0 && (
        <div className="loading-container">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <LoadingMessage type="description" identifier={description} language={i18n.language} storedPreferences={storedPreferences} /> {/* Updated component */}
        </div>
      )}
      {searchResults.length > 0 && <SearchResultsPage results={searchResults} />}
      {showMoreButton && (
        <>
          <button onClick={handleLoadMore} className="load-more-results" disabled={loadingMore}>
            {loadingMore ? t('search_form:loading') : t('search_form:more_attractions')}
          </button>
        </>
      )}
    </BaseLayout>
  );
};

export default AttractionsByDescriptionPage;
