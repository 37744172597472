// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: 'Open Sans', sans-serif;
  background-color: #4CAF50;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
}
h1 {
  text-align: center;
  color: white;
  margin-top: none;
  font-size: 2em;
  background-color: #4CAF50;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
label,
.preference-label {
  font-size: 1.2em;
  color: #1d1d1d;
  font-weight: bold;
}
.description,
.preference-description {
  font-size: 0.9em;
  color: #6a6a6a;
}
button {
  font-size: 1.2em;
  color: white;
  background-color: #000000;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}
button:hover {
  background-color: #4CAF50;
}
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
main {
  flex-grow: 1;
  box-sizing: border-box;
}
main * {
  box-sizing: border-box;
}
/* Ensure no overflow from children */
main > * {
  max-width: 100%;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/styles/App.less"],"names":[],"mappings":"AAEA;EACE,oCAAA;EACA,yBAAA;EACA,SAAA;EACA,UAAA;AADF;AAKA;;;;;;EACE,2CAAA;EACA,iBAAA;AAEF;AACA;EACE,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,cAAA;EACA,yBAAA;EACA,2CAAA;AACF;AAEA;;EACE,gBAAA;EACA,cAAA;EACA,iBAAA;AACF;AAEA;;EACE,gBAAA;EACA,cAAA;AACF;AAGA;EACE,gBAAA;EACA,YAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;AADF;AAGE;EACE,yBAAA;AADJ;AAMA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;AAJF;AAOA;EACE,YAAA;EACA,sBAAA;AALF;AAQA;EACE,sBAAA;AANF;AACA,qCAAqC;AASrC;EACE,eAAA;EACA,gBAAA;AAPF","sourcesContent":["@import 'Variables.less';\n\nbody {\n  font-family: 'Open Sans', sans-serif;\n  background-color: @mainColor;\n  margin: 0;\n  padding: 0;\n}\n\n// Global typography styles\nh1, h2, h3, h4, h5, h6 {\n  font-family: 'Roboto Condensed', sans-serif;\n  font-weight: bold;\n}\n\nh1 {\n  text-align: center;\n  color: white;\n  margin-top: none;\n  font-size: 2em;\n  background-color: @mainColor;\n  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);\n}\n\nlabel, .preference-label {\n  font-size: 1.2em;\n  color: darken(@itemBackground, 80%);\n  font-weight: bold;\n}\n\n.description, .preference-description {\n  font-size: 0.9em;\n  color: darken(@itemBackground, 50%);\n}\n\n// Ensures all buttons in the app have a consistent style\nbutton {\n  font-size: 1.2em;\n  color: white;\n  background-color: @buttonColor; \n  border: none;\n  border-radius: 5px;\n  padding: 10px 20px;\n  cursor: pointer;\n\n  &:hover {\n    background-color: @buttonHoverColor;\n  }\n}\n\n// New styles to ensure the footer stays at the bottom\n.app-container {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n\nmain {\n  flex-grow: 1;\n  box-sizing: border-box;\n}\n\nmain * {\n  box-sizing: border-box;\n}\n\n/* Ensure no overflow from children */\nmain > * {\n  max-width: 100%;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
