// frontend/src/components/Footer/Footer.js

import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.less';

const Footer = () => {
    const { t, ready } = useTranslation(['footer', 'privacy']); // Load the necessary namespaces

    if (!ready) {
        return <div>Loading...</div>; // Show a loading state while translations are loading
    }

    return (
        <footer className="footer">
            <div className="footer-container">
                <p className="disclaimer">
                    {t('footer:disclaimer')}
                    <a href='/privacy-policy'>{t('privacy:privacy_policy')}</a>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
