// frontend/src/components/SearchResultsPage/SearchResultsPage.js

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './SearchResultsPage.less';
import { FaPlus, FaInfoCircle, FaChevronUp, FaRegTimesCircle, FaInfo, FaDollarSign, FaLightbulb, FaExclamationTriangle, FaClock, FaMapMarkerAlt, FaParking, FaPhone, FaMapSigns, FaCalendarAlt, FaCloudSun, FaWheelchair, FaTag, FaThumbsUp, FaUsers, FaHourglassHalf, FaGlobe, FaRoute } from 'react-icons/fa';
import LocationMap from './LocationMap'; // Adjust the path as necessary
import { Pagination, Navigation } from 'swiper/modules';

const attributeMap = {
  historical_significance: 'search_results:historical_significance',
  cultural_relevance: 'search_results:cultural_relevance',
  educational_value: 'search_results:educational_value',
  fun_factor: 'search_results:fun_factor',
  accessibility: 'search_results:accessibility',
  amenities: 'search_results:amenities',
  photo_worthiness: 'search_results:photo_worthiness',
  interactivity: 'search_results:interactivity',
  kid_friendliness: 'search_results:kid_friendliness',
  duration: 'search_results:duration',
  nearby_food: 'search_results:nearby_food',
  popularity: 'search_results:popularity',
  pet_friendly: 'search_results:pet_friendly',
  free: 'search_results:free'
};

const emojiMap = {
  historical_significance: '🏛️',
  cultural_relevance: '🌍',
  educational_value: '📚',
  fun_factor: '🎢',
  accessibility: '♿',
  amenities: '🚻',
  photo_worthiness: '📸',
  interactivity: '👐',
  kid_friendliness: '👶',
  duration: '⏰',
  nearby_food: '🍴',
  popularity: '🌟',
  pet_friendly: '🐾',
  free: '💸'
};

const detailMap = {
  description_short: 'search_results:description_short',
  admission_fee: 'search_results:admission_fee',
  tips: 'search_results:tips',
  warnings: 'search_results:warnings',
  best_time_to_visit: 'search_results:best_time_to_visit',
  nearby_attractions: 'search_results:nearby_attractions',
  parking_info: 'search_results:parking_info',
  contact_info: 'search_results:contact_info',
  operating_hours: 'search_results:operating_hours',
  event_schedule: 'search_results:event_schedule',
  weather_considerations: 'search_results:weather_considerations',
  accessibility_features: 'search_results:accessibility_features',
  special_offers: 'search_results:special_offers',
  reviews_testimonials: 'search_results:reviews_testimonials',
  social_media_links: 'search_results:social_media_links',
  recommended_duration: 'search_results:recommended_duration',
  age_suitability: 'search_results:age_suitability',
  virtual_tour_url: 'search_results:virtual_tour_url',
  approximate_location: 'search_results:approximate_location',
  address: 'search_results:address',
  directions: 'search_results:directions'
};

const detailIcons = {
  description_short: <FaInfo />,
  admission_fee: <FaDollarSign />,
  tips: <FaLightbulb />,
  warnings: <FaExclamationTriangle />,
  best_time_to_visit: <FaClock />,
  nearby_attractions: <FaMapMarkerAlt />,
  parking_info: <FaParking />,
  contact_info: <FaPhone />,
  operating_hours: <FaClock />,
  event_schedule: <FaCalendarAlt />,
  weather_considerations: <FaCloudSun />,
  accessibility_features: <FaWheelchair />,
  special_offers: <FaTag />,
  reviews_testimonials: <FaThumbsUp />,
  social_media_links: <FaGlobe />,
  recommended_duration: <FaHourglassHalf />,
  age_suitability: <FaUsers />,
  virtual_tour_url: <FaGlobe />,
  approximate_location: <FaMapSigns />,
  address: <FaMapMarkerAlt />,
  directions: <FaRoute />
};

const exclusions = ['seo_file_path', 'description', 'description2', 'description_short', 'language_code'];

const prioritizedDetailKeys = [
  'address',
  'directions',
  'contact_info',
  'operating_hours',
  'admission_fee',
  'parking_info',
  'best_time_to_visit',
  'recommended_duration',
  'tips',
  'warnings',
  'weather_considerations',
  'age_suitability',
  'event_schedule',
  'accessibility_features',
  'social_media_links',
  'virtual_tour_url',
  'nearby_attractions'
];

const prioritizedRatingKeys = [
  'free',
  'pet_friendly',
  'nearby_food',
  'popularity',
  'fun_factor',
  'amenities',
  'photo_worthiness',
  'duration',
  'historical_significance',
  'cultural_relevance',
  'educational_value',
  'accessibility',
  'interactivity',
  'kid_friendliness',
];

const SearchResultsPage = ({ results }) => {
  const { t } = useTranslation('search_results');
  const [visibleResults, setVisibleResults] = useState(results);
  const [expandedInfo, setExpandedInfo] = useState({});
  const [visibleInfo, setVisibleInfo] = useState({});
  const [savedAttractions, setSavedAttractions] = useState(() => {
    const saved = localStorage.getItem('saved-attractions');
    return saved ? JSON.parse(saved) : [];
  });

  useEffect(() => {
    setVisibleResults(results);
  }, [results]);

  const handleMoreInfoClick = (result, event) => {
    event.stopPropagation();
    const { name: attractionName } = result;

    if (!expandedInfo[attractionName]) {
      setExpandedInfo((prev) => ({ ...prev, [attractionName]: true }));
      setVisibleInfo((prev) => ({ ...prev, [attractionName]: true }));
    } else {
      setVisibleInfo((prev) => ({ ...prev, [attractionName]: !prev[attractionName] }));
    }
  };

  const handleHideMoreInfoClick = (attractionName, event) => {
    event.stopPropagation();
    setVisibleInfo((prev) => ({ ...prev, [attractionName]: false }));
  };

  const handleSaveAttraction = (result, event) => {
    event.stopPropagation();
    const newAttraction = {
      file_path: result.file_path
    };
    const updatedSavedAttractions = [...savedAttractions, newAttraction];
    setSavedAttractions(updatedSavedAttractions);
    localStorage.setItem('saved-attractions', JSON.stringify(updatedSavedAttractions));
  };

  const handleUnsaveAttraction = (result, event) => {
    event.stopPropagation();
    const updatedSavedAttractions = savedAttractions.filter(attraction => attraction.file_path !== result.file_path);
    setSavedAttractions(updatedSavedAttractions);
    localStorage.setItem('saved-attractions', JSON.stringify(updatedSavedAttractions));
  };

  const isAttractionSaved = (result) => {
    return savedAttractions.some(attraction => attraction.file_path === result.file_path);
  };

  const filterExclusions = (data) => {
    return Object.keys(data).filter(key => !exclusions.includes(key) && !key.endsWith('_d')).reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {});
  };

  const orderedDetailKeys = (data) => {
    const filteredData = filterExclusions(data);
    const keys = Object.keys(filteredData);
    const prioritized = prioritizedDetailKeys.filter(key => keys.includes(key));
    const remaining = keys.filter(key => !prioritizedDetailKeys.includes(key));
    return [...prioritized, ...remaining];
  };

  const orderedRatingKeys = (data) => {
    const filteredData = filterExclusions(data);
    const keys = Object.keys(filteredData);
    const prioritized = prioritizedRatingKeys.filter(key => keys.includes(key));
    const remaining = keys.filter(key => !prioritizedRatingKeys.includes(key));
    return [...prioritized, ...remaining];
  };

  const unescapeQuotes = (text) => {
    return text ? text.replace(/\\"/g, '"') : '';
  };

  return (
    <div className="search-results-container">
      {visibleResults.map(result => (
        <div key={result.name} className="result-item animate__animated animate__zoomInDown">
          <div className="result-header">
            <h2 className="result-name">{result.name}</h2>
            <p className="result-location">{result.location}</p>
          </div>
          <div className="result-info">
            <p className="result-description">{unescapeQuotes(result.details.description_short)}</p>
            {result.images && result.images.length > 0 ? (
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
              >
                {result.images.map((image, index) => (
                  <SwiperSlide key={index}>
                    <img src={image} alt={`${result.name} ${index + 1}`} className="result-image" />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : result.image ? (
              <img src={result.image} alt={result.name} className="result-image" />
            ) : null}
            {visibleInfo[result.name] && (
              <div className='animate__animated animate__fadeIn'>
                <button className="hide-more-info-button-top" onClick={(e) => handleHideMoreInfoClick(result.name, e)}>
                  <FaChevronUp className="button-icon" /> {t('search_results:hide_more_info')}
                </button>
                {result.details.address && <LocationMap address={result.details.address} />}
                <div className="additional-info">
                  <p className="expanded-description">{unescapeQuotes(result.details.description)}</p>
                  <p className="expanded-description">{unescapeQuotes(result.details.description2)}</p>
                  <div className="details-section">
                    {orderedDetailKeys(result.details).map((key) => (
                      result.details[key] && (
                        <div key={key} className="detail-item">
                          <div className="detail-header">
                            <span className="detail-icon">{detailIcons[key]}</span>
                            <strong>{t(detailMap[key])}:</strong>
                          </div>
                          <div className="detail-content">
                            {key === 'address' ? (
                              <a href={`https://maps.google.com/?q=${encodeURIComponent(result.details[key])}`} target="_blank" rel="noopener noreferrer">
                                {result.details[key]}
                              </a>
                            ) : (
                              unescapeQuotes(result.details[key])
                            )}
                          </div>
                        </div>
                      )
                    ))}
                  </div>
                  <div className="ratings">
                    {orderedRatingKeys(result.ratings).map((key) => (
                      <div key={key} className="rating-item">
                        <hr />
                        <div className="progress-bar-container">
                          <div className="progress-bar-label">
                            {emojiMap[key]} {t(attributeMap[key])} {typeof result.ratings[key] === 'boolean' ? '' : `(${result.ratings[key]}/5)`}
                          </div>
                          {typeof result.ratings[key] === 'boolean' ? (
                            <div className="boolean-value">{result.ratings[key] ? <strong>{t('search_results:yes')}</strong> : <strong>{t('search_results:no')}</strong>}</div>
                          ) : (
                            <div className="progress-bar">
                              <div className="progress" style={{ width: `${(result.ratings[key] / 5) * 100}%` }}></div>
                            </div>
                          )}
                        </div>
                        <p>{unescapeQuotes(result.ratings[`${key}_d`])}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="result-buttons">
            <button className="more-info-button" onClick={(e) => handleMoreInfoClick(result, e)}>
              <FaInfoCircle className="button-icon" /> {visibleInfo[result.name] ? t('search_results:hide_more_info') : t('search_results:more_info')}
            </button>
            {isAttractionSaved(result) ? (
              <button className="unsave-button" onClick={(e) => handleUnsaveAttraction(result, e)}>
                <FaRegTimesCircle className="button-icon" /> {t('search_results:unsave_attraction')}
              </button>
            ) : (
              <button className="save-button" onClick={(e) => handleSaveAttraction(result, e)}>
                <FaPlus className="button-icon" /> {t('search_results:save_attraction')}
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SearchResultsPage;
