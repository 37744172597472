import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SearchResultsPage from '../SearchResultsPage/SearchResultsPage';
import './SavedAttractionsPage.less';
import BaseLayout from '../Common/BaseLayout';
import { Helmet } from 'react-helmet';

const SavedAttractionsPage = () => {
  const { t } = useTranslation('saved_attractions'); // Specify the namespace here
  const [savedAttractions, setSavedAttractions] = useState([]);
  const [loadedAttractions, setLoadedAttractions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('Component mounted, setting loading to true');
    const saved = localStorage.getItem('saved-attractions');
    if (saved) {
      setSavedAttractions(JSON.parse(saved));
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetchAttractions = async () => {
      if (savedAttractions.length === 0) {
        setLoading(false);
        console.log('No saved attractions, setting loading to false');
        return;
      }

      // Map the saved attractions to extract just the file paths
      const filePaths = savedAttractions.map(attr => attr.file_path);

      const response = await fetch('/api/attractions-by-saved', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ file_paths: filePaths }) // Send the file_paths array to the server
      });

      const data = await response.json();
      console.log(data);
      setLoadedAttractions(data);
      setLoading(false);
      console.log('Data fetched, setting loading to false');
    };

    if (savedAttractions.length > 0) {
      setLoading(true);
      fetchAttractions();
    }
  }, [savedAttractions]);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": loadedAttractions.map((result, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": `https://roadsideattracts.com/attractions${result.file_path.replace('.json', '.html')}`
    }))
  };

  return (
    <BaseLayout title={t('saved_attractions')} subtitle={t('your_saved_attractions')}>
      <Helmet>
        <title>Saved Attractions - RoadsideAttracts</title>
        <meta name="description" content="View your saved attractions on RoadsideAttracts. Discover unique destinations that you have marked to explore later." />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      {loading ? (
        <div className="loading-container">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      ) : (savedAttractions.length === 0 ? (
        <p>{t('no_attractions_saved')}</p>
      ) : (
        <SearchResultsPage results={loadedAttractions} />
      ))}
    </BaseLayout>
  );
};

export default SavedAttractionsPage;
