// frontend/src/components/PreferencesPage/PreferencesPage.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './PreferencesPage.less';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';

const PreferencesPage = ({ onHidePreferences, onChangePreferences }) => {
  const { t } = useTranslation('preferences'); // Load the 'preferences' namespace
  const [preferences, setPreferences] = useState({
    historical_significance: 3,
    cultural_relevance: 3,
    educational_value: 3,
    fun_factor: 3,
    accessibility: 3,
    amenities: 3,
    photo_worthiness: 3,
    interactivity: 3,
    kid_friendliness: 3,
    duration: 3,
    nearby_food: false,
    popularity: 3,
    pet_friendly: false,
    free: false
  });

  useEffect(() => {
    const storedPreferences = localStorage.getItem('search_preferences');
    if (storedPreferences) {
      setPreferences(JSON.parse(storedPreferences));
    }
  }, []);

  const savePreferences = (updatedPreferences) => {
    localStorage.setItem('search_preferences', JSON.stringify(updatedPreferences));
  };

  const handleSliderChange = (event) => {
    const { name, value, type, checked } = event.target;
    const updatedPreferences = {
      ...preferences,
      [name]: type === 'checkbox' ? checked : parseInt(value)
    };
    setPreferences(updatedPreferences);
    savePreferences(updatedPreferences);
    onChangePreferences(); // Clear search results when preferences change
  };

  const handleCheckboxChange = (name) => {
    const updatedPreferences = {
      ...preferences,
      [name]: !preferences[name]
    };
    setPreferences(updatedPreferences);
    savePreferences(updatedPreferences);
    onChangePreferences(); // Clear search results when preferences change
  };

  const labels = {
    historical_significance: t('preferences:historical_significance'),
    cultural_relevance: t('preferences:cultural_relevance'),
    educational_value: t('preferences:educational_value'),
    fun_factor: t('preferences:fun_factor'),
    accessibility: t('preferences:accessibility'),
    amenities: t('preferences:amenities'),
    photo_worthiness: t('preferences:photo_worthiness'),
    interactivity: t('preferences:interactivity'),
    kid_friendliness: t('preferences:kid_friendliness'),
    duration: t('preferences:duration'),
    nearby_food: t('preferences:nearby_food'),
    popularity: t('preferences:popularity'),
    pet_friendly: t('preferences:pet_friendly'),
    free: t('preferences:free')
  };

  const emojiMap = {
    historical_significance: '🏛️',
    cultural_relevance: '🌍',
    educational_value: '📚',
    fun_factor: '🎢',
    accessibility: '♿',
    amenities: '🚻',
    photo_worthiness: '📸',
    interactivity: '👐',
    kid_friendliness: '👶',
    duration: '⏰',
    nearby_food: '🍔',
    popularity: '🌟',
    pet_friendly: '🐾',
    free: '🆓'
  };

  const descriptions = {
    historical_significance: t('preferences:historical_significance_desc'),
    cultural_relevance: t('preferences:cultural_relevance_desc'),
    educational_value: t('preferences:educational_value_desc'),
    fun_factor: t('preferences:fun_factor_desc'),
    accessibility: t('preferences:accessibility_desc'),
    amenities: t('preferences:amenities_desc'),
    photo_worthiness: t('preferences:photo_worthiness_desc'),
    interactivity: t('preferences:interactivity_desc'),
    kid_friendliness: t('preferences:kid_friendliness_desc'),
    duration: t('preferences:duration_desc'),
    nearby_food: t('preferences:nearby_food_desc'),
    popularity: t('preferences:popularity_desc'),
    pet_friendly: t('preferences:pet_friendly_desc'),
    free: t('preferences:free_desc')
  };

  const valueLabels = {
    historical_significance: {
      0: t('preferences:low'),
      1: t('preferences:slight'),
      2: t('preferences:moderate'),
      3: t('preferences:high'),
      4: t('preferences:very_high'),
      5: t('preferences:max')
    },
    cultural_relevance: {
      0: t('preferences:low'),
      1: t('preferences:slight'),
      2: t('preferences:moderate'),
      3: t('preferences:high'),
      4: t('preferences:very_high'),
      5: t('preferences:max')
    },
    educational_value: {
      0: t('preferences:low'),
      1: t('preferences:slight'),
      2: t('preferences:moderate'),
      3: t('preferences:high'),
      4: t('preferences:very_high'),
      5: t('preferences:max')
    },
    fun_factor: {
      0: t('preferences:low'),
      1: t('preferences:slight'),
      2: t('preferences:moderate'),
      3: t('preferences:high'),
      4: t('preferences:very_high'),
      5: t('preferences:max')
    },
    accessibility: {
      0: t('preferences:low'),
      1: t('preferences:slight'),
      2: t('preferences:moderate'),
      3: t('preferences:high'),
      4: t('preferences:very_high'),
      5: t('preferences:max')
    },
    amenities: {
      0: t('preferences:low'),
      1: t('preferences:slight'),
      2: t('preferences:moderate'),
      3: t('preferences:high'),
      4: t('preferences:very_high'),
      5: t('preferences:max')
    },
    photo_worthiness: {
      0: t('preferences:low'),
      1: t('preferences:slight'),
      2: t('preferences:moderate'),
      3: t('preferences:high'),
      4: t('preferences:very_high'),
      5: t('preferences:max')
    },
    interactivity: {
      0: t('preferences:low'),
      1: t('preferences:slight'),
      2: t('preferences:moderate'),
      3: t('preferences:high'),
      4: t('preferences:very_high'),
      5: t('preferences:max')
    },
    kid_friendliness: {
      0: t("preferences:doesn't_matter"),
      1: t('preferences:slight'),
      2: t('preferences:moderate'),
      3: t('preferences:high'),
      4: t('preferences:very_high'),
      5: t('preferences:max')
    },
    duration: {
      0: t('preferences:quick_stop'),
      1: t('preferences:short_visit'),
      2: t('preferences:moderate_duration'),
      3: t('preferences:longer_visit'),
      4: t('preferences:half_day'),
      5: t('preferences:all_day')
    },
    popularity: {
      0: t('preferences:low'),
      1: t('preferences:slight'),
      2: t('preferences:moderate'),
      3: t('preferences:high'),
      4: t('preferences:very_high'),
      5: t('preferences:max')
    }
  };

  return (
    <div className="preferences-form-container animate__animated animate__fadeIn">
      <h2>{t('preferences:my_search_preferences')}</h2>
      <p>{t('preferences:preferences_description')}</p>
      <form className="preferences-form">
        {Object.keys(preferences).map(key => (
          <div key={key} className="preference-item">
            <div className="preference-header" onClick={() => typeof preferences[key] === 'boolean' && handleCheckboxChange(key)}>
              <label className="preference-label">{emojiMap[key]} {labels[key]}</label>
              {typeof preferences[key] === 'boolean' ? (
                <div className="preference-checkbox-container">
                  <FontAwesomeIcon icon={preferences[key] ? faCheckSquare : faSquare} size="2x" />
                </div>
              ) : (
                <span className="preference-value">
                  {valueLabels[key] && valueLabels[key][preferences[key]] !== undefined
                    ? valueLabels[key][preferences[key]]
                    : 'Low'}
                </span>
              )}
            </div>
            {typeof preferences[key] !== 'boolean' && (
              <input
                type="range"
                min="0"
                max="5"
                name={key}
                value={preferences[key]}
                onChange={handleSliderChange}
                className="preference-slider"
              />
            )}
            <div className="preference-description">{descriptions[key]}</div>
          </div>
        ))}
        <div className="button-container">
          <button type="button" onClick={onHidePreferences}>{t('preferences:close')}</button>
        </div>
      </form>
    </div>
  );
};

export default PreferencesPage;
