import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { FaMap, FaRoad, FaStar, FaSignInAlt, FaSignOutAlt, FaBars, FaKeyboard } from 'react-icons/fa';
import './Header.less';

const Header = () => {
  const { t, i18n, ready } = useTranslation('header'); // Load the 'header' namespace
  const [isOpen, setIsOpen] = useState(false);
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // if (!ready) {
  //   return <div>Loading...</div>; // Show a loading state while translations are loading
  // }

  return (
    <header className="header">
      <div className="header-container">
        <div className="site-name-container">
          <Link to="/" className="site-name">RoadsideAttracts</Link>
          <div className="tagline">{t('tag_line')}</div>
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          <FaBars />
        </div>
        <div className={`nav-links ${isOpen ? 'open' : ''}`}>
          <Link to="/" className="link" onClick={toggleMenu} title={t('nav_by_location')}>
            <FaMap /> &nbsp; {t('nav_by_location')}
          </Link>
          <Link to="/attractions-by-route" className="link" onClick={toggleMenu} title={t('nav_by_route')}>
            <FaRoad /> &nbsp; {t('nav_by_route')}
          </Link>
          <Link to="/attractions-by-description" className="link" onClick={toggleMenu} title={t('nav_by_description')}>
            <FaKeyboard /> &nbsp; {t('nav_by_description')}
          </Link>
          <Link to="/saved-attractions" className="link" onClick={toggleMenu} title={t('saved_attractions')}>
            <FaStar /> &nbsp; {t('saved_attractions')}
          </Link>
          {!isAuthenticated ? (
            <Link to="#" className="link" onClick={() => loginWithRedirect()} title={t('log_in')}>
              <FaSignInAlt /> &nbsp; {t('log_in')}
            </Link>
          ) : (
            <Link to="#" className="link" onClick={() => logout({ returnTo: window.location.origin })} title={t('log_out')}>
              <FaSignOutAlt /> &nbsp; {t('log_out')}
            </Link>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
