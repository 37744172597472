// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy-container {
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  text-align: left;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.privacy-policy-container h1,
.privacy-policy-container h2 {
  color: #000000;
  background: none;
  text-shadow: none;
  text-align: left;
}
.privacy-policy-container p {
  margin-bottom: 20px;
  line-height: 1.6;
}
`, "",{"version":3,"sources":["webpack://./src/components/PrivacyPolicyPage/PrivacyPolicyPage.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iCAAA;EACA,gBAAA;EACA,yBAAA;EACA,mBAAA;EACA,wCAAA;AACJ;AAEE;;EAEE,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAAJ;AAGE;EACE,mBAAA;EACA,gBAAA;AADJ","sourcesContent":[".privacy-policy-container {\n    padding: 20px;\n    font-family: 'Roboto', sans-serif;\n    text-align: left;\n    background-color: #ffffff;\n    border-radius: 10px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  }\n  \n  .privacy-policy-container h1,\n  .privacy-policy-container h2 {\n    color: #000000;\n    background: none;\n    text-shadow: none;\n    text-align: left;\n  }\n  \n  .privacy-policy-container p {\n    margin-bottom: 20px;\n    line-height: 1.6;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
