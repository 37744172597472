import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import './AttractionsByRoutePage.less';
import '../Common/SearchForm.less';
import BaseLayout from '../Common/BaseLayout';
import PreferencesPage from '../PreferencesPage/PreferencesPage';
import SearchResultsPage from '../SearchResultsPage/SearchResultsPage';
import LoadingMessage from '../LoadingMessage/LoadingMessage';
import { FaCog, FaLocationArrow, FaSpinner } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const AttractionsByRoutePage = () => {
  const { t, i18n, ready } = useTranslation('search_form'); // Load the 'search_form' namespace
  const location = useLocation();
  const [fromLocation, setFromLocation] = useState('');
  const [toLocation, setToLocation] = useState('');
  const [showPreferences, setShowPreferences] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [showLoadingMessage, setShowLoadingMessage] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [isLoadingFromLocation, setIsLoadingFromLocation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showDefaultMessage, setShowDefaultMessage] = useState(true); // Add state for default message

  const resultsPerPage = 8;

  const storedPreferences = useMemo(() => {
    return JSON.parse(localStorage.getItem('search_preferences')) || {};
  }, []);

  const fetchResults = useCallback(async (reset = false, fromQuery = fromLocation, toQuery = toLocation) => {
    if (reset) {
      setIsLoading(true);
    } else {
      setLoadingMore(true);
    }

    const data = {
      criteria: {
        count: resultsPerPage,
        from: fromQuery,
        to: toQuery,
        language: i18n.language,
        preferences: storedPreferences,
      },
      new_search: reset,
    };

    try {
      const response = await fetch('/api/attractions-by-tour', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) throw new Error('Network response was not ok.');

      const newResults = await response.json();
      setIsLoading(false);
      setLoadingMore(false);

      if (reset) {
        setSearchResults(newResults);
        setShowMoreButton(true);
      } else {
        setSearchResults((prevResults) => [...prevResults, ...newResults]);
        setShowMoreButton(true);
      }

      if (newResults.length > 0) {
        setShowLoadingMessage(false);
        setShowDefaultMessage(false); // Hide default message
      }
    } catch (error) {
      console.error('Failed to submit locations:', error);
      setIsLoading(false);
      setLoadingMore(false);
    }
  }, [fromLocation, toLocation, i18n.language, resultsPerPage, storedPreferences]);

  const debouncedFetchResults = useMemo(
    () => _.debounce((reset, fromQuery, toQuery) => fetchResults(reset, fromQuery, toQuery), 300),
    [fetchResults]
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const fromQuery = params.get('from');
    const toQuery = params.get('to');
    if (fromQuery && toQuery) {
      setFromLocation(fromQuery);
      setToLocation(toQuery);
      debouncedFetchResults(true, fromQuery, toQuery);
    }
  }, [location.search, debouncedFetchResults]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!fromLocation.trim() || !toLocation.trim()) {
      setErrorMessage(t('please_enter_both_search_terms'));
      return;
    }
    setErrorMessage('');
    setShowMoreButton(false);
    setLoadingMore(false);
    setShowLoadingMessage(true);
    setShowDefaultMessage(false); // Hide default message
    await fetchResults(true);
  };

  const handleFromLocationClick = () => {
    if (navigator.geolocation && !isLoadingFromLocation) {
      setIsLoadingFromLocation(true);
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const response = await fetch('/api/geolocate', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ lat: latitude, long: longitude, language: i18n.language }),
          });
          if (!response.ok) throw new Error('Failed to fetch location data.');
          const data = await response.json();
          setFromLocation(data.location);
        } catch (error) {
          console.error('Error fetching location data:', error);
        } finally {
          setIsLoadingFromLocation(false);
        }
      }, (error) => {
        console.error('Error getting location:', error);
        setIsLoadingFromLocation(false);
      });
    } else {
      console.error('Geolocation is not supported by this browser or is already loading.');
    }
  };

  const handleLoadMore = async () => {
    await fetchResults(false);
  };

  const clearSearchResults = () => {
    setSearchResults([]);
    setShowMoreButton(false);
    setShowDefaultMessage(true); // Show default message
  };

  useEffect(() => {
    clearSearchResults();
  }, [fromLocation, toLocation]);

  // if (!ready) {
  //   return <div>Loading...</div>; // Show a loading state while translations are loading
  // }

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": searchResults.map((result, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": `https://roadsideattracts.com/attractions${result.file_path.replace('.json', '.html')}`
    }))
  };

  return (
    <BaseLayout title={t('attractions_by_tour_page_title')} subtitle={t('attractions_by_tour_page_subtitle')}>
      <Helmet>
        <title>Attractions by Route - RoadsideAttracts</title>
        <meta name="description" content="Find attractions along your route with RoadsideAttracts. Customize your search with various preferences and discover unique destinations." />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="attractions-form-container">
        <form onSubmit={handleSubmit} className="attractions-form">
          <div className="row">
            <div className="location-icon" onClick={handleFromLocationClick}>
              {isLoadingFromLocation ? <FaSpinner className="loading-spinner" /> : <FaLocationArrow className="location-arrow-icon" />}
            </div>
            <input
              type="text"
              placeholder={t('from')}
              value={fromLocation}
              onChange={(e) => setFromLocation(e.target.value)}
              disabled={isLoading || loadingMore}
            />
          </div>

          <div className="row">
            <div className="empty-icon" /> {/* Empty icon column for alignment */}
            <input
              type="text"
              placeholder={t('to')}
              value={toLocation}
              onChange={(e) => setToLocation(e.target.value)}
              disabled={isLoading || loadingMore}
            />
          </div>
          <div className="row">
            <div className="search-preferences-icon" onClick={() => setShowPreferences(!showPreferences)}>
              <FaCog className="preferences-icon" />
            </div>
            <button type="submit" disabled={isLoading || loadingMore}>
              {isLoading || loadingMore ? t('loading') : t('search_now')}
            </button>
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </form>
        {showPreferences && <PreferencesPage onHidePreferences={() => setShowPreferences(false)} onChangePreferences={clearSearchResults} />}
      </div>

      {showDefaultMessage && !showLoadingMessage && !searchResults.length && (
        <div className="tour-message-container animate__animated animate__zoomInUp">
          <p>{t('search_form:route_field_default_message')}</p>
        </div>
      )}
      {isLoading && !showLoadingMessage && (
        <div className="loading-container">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      )}
      {showLoadingMessage && searchResults.length === 0 && (
        <div className="loading-container">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <LoadingMessage type="tour" from={fromLocation} to={toLocation} language={i18n.language} storedPreferences={storedPreferences} />
        </div>
      )}
      {searchResults.length > 0 && <SearchResultsPage results={searchResults} />}
      {showMoreButton && (
        <>
          <button onClick={handleLoadMore} className="load-more-results" disabled={loadingMore}>
            {loadingMore ? t('loading') : t('more_attractions')}
          </button>
          {loadingMore && (
            <div className="loading-container">
              <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
              </div>
            </div>
          )}
        </>
      )}
    </BaseLayout>
  );
};

export default AttractionsByRoutePage;
