import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/App.less';
import Header from './components/Header/Header.js';
import Footer from './components/Footer/Footer.js'; // You can keep this for now
import AttractionsByLocationPage from './components/AttractionsByLocationPage/AttractionsByLocationPage.js';
import AttractionsByDescriptionPage from './components/AttractionsByDescriptionPage/AttractionsByDescriptionPage.js';
import AttractionsByRoutePage from './components/AttractionsByRoutePage/AttractionsByRoutePage.js';
import SavedAttractionsPage from './components/SavedAttractionsPage/SavedAttractionsPage.js';
import PrivacyPolicyPage from './components/PrivacyPolicyPage/PrivacyPolicyPage.js';
import AuthProvider from './auth-provider';
import './i18n'; // Import the i18next configuration

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="app-container">
          <Header />
          <main>
            <Routes>
              <Route path="/" element={<AttractionsByLocationPage />} />
              <Route path="/attractions-by-route" element={<AttractionsByRoutePage />} />
              <Route path="/attractions-by-description" element={<AttractionsByDescriptionPage />} />
              <Route path="/saved-attractions" element={<SavedAttractionsPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
