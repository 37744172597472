// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-container {
  padding: 20px;
  text-align: center;
  width: 100%;
  max-width: 700px;
  /* Adjust width as necessary */
  margin: auto;
  /* Center the form horizontally */
}
.layout-title {
  margin: 20px;
  /* Space above the form */
}
.layout-title h1 {
  font-size: 3em;
  color: #ffffff;
  margin: 0;
  padding: 0;
}
.layout-subtitle {
  margin: 0;
  /* Removing default margins */
  font-family: 'Roboto', sans-serif;
  color: #000000;
  font-size: 1.4em;
}
@media (max-width: 768px) {
  .layout-container {
    padding: 10px;
    width: calc(100% - 20px);
  }
  .layout-title h1 {
    font-size: 2em;
    /* Adjust font size for better readability on mobile */
  }
  .layout-subtitle {
    font-size: 1.2em;
    /* Adjust font size for better readability on mobile */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Common/BaseLayout.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,8BAA8B;EAA9B,YAAA;EAEA,iCAAiC;AACnC;AAAA;EACE,YAAA;EAEA,yBAAyB;AAC3B;AAAA;EACE,cAAA;EACA,cAAA;EACA,SAAA;EACA,UAAA;AAEF;AACA;EACE,SAAA;EACA,6BAA6B;EAA7B,iCAAA;EACA,cAAA;EACA,gBAAA;AAEF;AACA;EACE;IACI,aAAA;IACA,wBAAA;EACJ;EACA;IACI,cAAA;IACF,sDAAsD;EACxD;EAAA;IACI,gBAAA;IAEF,sDAAsD;EACxD;AACF","sourcesContent":[".layout-container {\n  padding: 20px;\n  text-align: center;\n  width: 100%;\n  max-width: 700px; /* Adjust width as necessary */\n  margin: auto; /* Center the form horizontally */\n}\n\n.layout-title {\n  margin: 20px; /* Space above the form */\n}\n\n.layout-title h1 {\n  font-size: 3em;\n  color: #ffffff;\n  margin: 0;\n  padding: 0;\n}\n\n.layout-subtitle {\n  margin: 0; /* Removing default margins */\n  font-family: 'Roboto', sans-serif;\n  color: #000000;\n  font-size: 1.4em;\n}\n\n@media (max-width: 768px) {\n  .layout-container {\n      padding: 10px;\n      width: calc(100% - 20px);\n  }\n  .layout-title h1 {\n      font-size: 2em; /* Adjust font size for better readability on mobile */\n  }\n  .layout-subtitle {\n      font-size: 1.2em; /* Adjust font size for better readability on mobile */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
